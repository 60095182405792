<template>
  <div>
    <new-transaction-popup v-if="newTransactionActive" @success="getData" @closeTopup="togglePopup" />
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card>
          <div slot="header">
            <h3>
              {{ cashier.name + ' ' + $t('Cashier Details') }}
            </h3>
          </div>
          <vs-row vs-justify="center">
            <vs-col class="vx-col sm:w-3/12 mr-5">
              <vx-card :title="$t('Total Outcome')">
                <h5> {{ new Intl.NumberFormat('tr-TR', {
                  minimumFractionDigits: 2,
                }).format(totalOutcome) }} TL</h5>
              </vx-card>
            </vs-col>
            <vs-col class="vx-col sm:w-3/12 mr-5">
              <vx-card :title="$t('Total Income')">
                <h5> {{ new Intl.NumberFormat('tr-TR', {
                  minimumFractionDigits: 2,
                }).format(totalIncome) }} TL </h5>
              </vx-card>
            </vs-col>
            <vs-col class="vx-col sm:w-3/12">
              <vx-card :title="$t('Total Balance')">
                <h5>{{ new Intl.NumberFormat('tr-TR', {
                  minimumFractionDigits: 2,
                }).format((totalIncome - totalOutcome)) }} TL </h5>
              </vx-card>
            </vs-col>
          </vs-row>
          <vs-row vs-justify="center">
            <vs-col class="mt-6 pt-6">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                  <vs-button color="warning" @click="activePrompt = true" class="mr-3" type="gradient">
                    <font-awesome-icon class="mr-4" :icon="['fas', 'print']" />
                    {{ $t('Export Selected') }}
                  </vs-button>
                </vs-col>
                <vs-prompt :title="$t('Export To Excel')" class="export-options" @cancle="clearFields"
                  @accept="exportToExcel" :accept-text="$t('Export')" :cancel-text="$t('Cancel')" @close="clearFields"
                  :active.sync="activePrompt">
                  <vs-input v-model="fileName" :placeholder="$t('Enter File Name..')" class="w-full" />
                  <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                  <div class="flex mt-4">
                    <span class="mr-2">{{ $t('Cell Auto Width') }}</span>
                    <vs-switch v-model="cellAutoWidth">{{ $t('Cell Auto Width') }}</vs-switch>
                  </div>
                </vs-prompt>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="7">
                  <vs-button @click="togglePopup(true)" color="success" type="gradient">
                    <font-awesome-icon class="mr-4" :icon="['fas', 'exchange-alt']" />
                    {{ $t('Transfer Money') }}
                  </vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8"></vs-col>
              </vs-row>
            </vs-col>


          </vs-row>

          <div>
            <div class="vx-row mt-5">
              <div v-if="queryStrings" class="vx-col sm:w-1/12">
                <vs-button style="width:99%" @click="cleaner()" class="w-full mt-4">
                  <font-awesome-icon :icon="['fas', 'times']" />
                </vs-button>
              </div>
              <div class="vx-col sm:w-2/12 w-full">
                <vs-input class="w-full" :label="$t('Description')" style="width:99%" v-model="filter.description" />
              </div>

              <div class="vx-col sm:w-2/12 w-full">
                <my-select autocomplete class="selectExample " :label="$t('Category')" style="width:99%"
                  @change="checkSubCategory" v-model="filter.category">
                  <vs-select-item :text="$t('Select Category')" />
                  <vs-select-item :key="index" :value="item.name" :text="item.name"
                    v-for="(item, index) in categories" />
                </my-select>
              </div>

              <div v-if="subcategories.length > 0 && filter.category" class="vx-col sm:w-2/12 w-full">
                <my-select autocomplete :placeholder="$t('Select Sub Category')" style="width:99%"
                  class="selectExample mt-5" v-model="filter.sub_category">
                  <vs-select-item :text="$t('Select Sub Category')" />
                  <vs-select-item :key="index" :value="item.name" :text="item.name"
                    v-for="(item, index) in subcategories" />
                </my-select>
              </div>
              <div class="vx-col sm:w-2/12 ">
                <vs-input id="calendar" type="date" :max="filter.issue_date_end" style="width:99%"
                  :label="$t('Transaction Date - Start')" v-model="filter.issue_date" />
              </div>
              <div class="vx-col sm:w-2/12 ">
                <vs-input id="calendar" type="date" :min="filter.issue_date" style="width:99%"
                  :label="$t('Transaction Date - End')" v-model="filter.issue_date_end" />
              </div>
            </div>
            <vs-pagination-table model_type="cashier" :transaction="$route.params.cashierId" stripe
              :data="transactions">
              <template slot-scope="{ data }">
                <template>
                  <vs-th sort-key="namet">{{ $t('Transaction Date') }}</vs-th>
                  <vs-th>{{ $t('Category') }}</vs-th>
                  <vs-th>{{ $t('Income') }}</vs-th>
                  <vs-th>{{ $t('Outcome') }}</vs-th>
                  <vs-th>{{ $t('Balance') }}</vs-th>
                  <vs-th>{{ $t('Beneficiary') }}</vs-th>
                  <vs-th>{{ $t('Affected') }}</vs-th>
                  <vs-th>{{ $t('Description') }}</vs-th>
                  <vs-th>{{ $t('Transaction Owner') }}</vs-th>
                  <vs-th>{{ $t('Options') }}</vs-th>
                </template>

                <template>
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.issueDate">
                      <vs-chip transparent color="primary" v-if="tr.issue_date">
                        <vs-avatar color="rgb(var(--vs-primary))" icon="event_note" />
                        <vx-tooltip transparent color="primary" :text="tr.issue_date">
                          {{ tr.issue_date }}
                        </vx-tooltip>
                      </vs-chip>
                    </vs-td>
                    <vs-td :data="tr.category">
                      <span v-if="tr.child">
                        {{ tr.child.name }} /
                      </span>
                      {{ tr.category }}
                    </vs-td>

                    <vs-td :data="tr.income" v-bind:style="tr.income ? 'min-width:150px' : ''">
                      <font-awesome-icon color="rgb(var(--vs-success))" :icon="['fas', 'arrow-down']"
                        v-if="tr.income" />
                      {{ tr.income ? tr.income + ' TL' : '-' }}
                    </vs-td>
                    <vs-td :data="tr.outcome" v-bind:style="tr.outcome ? 'min-width:150px' : ''">
                      <font-awesome-icon color="rgb(var(--vs-danger))" :icon="['fas', 'arrow-up']" v-if="tr.outcome" />
                      {{ tr.outcome ? tr.outcome + ' TL' : '-' }}
                    </vs-td>
                    <vs-td :data="tr.balance" v-bind:style="tr.balance ? 'min-width:150px' : ''">
                      <font-awesome-icon color="rgb(var(--vs-warning))" :icon="['fas', 'exchange-alt']"
                        v-if="tr.balance" />
                      {{ tr.balance }} TL
                    </vs-td>
                    <vs-td>
                      <a v-if="tr.beneficiary && tr.beneficiary_type === 'account'" :href="$router.resolve({
                        name: 'AccountTransactions',
                        params: { accountId: tr.beneficiary_id }
                      }).href">
                        <vs-chip transparent color="success">
                          <vs-avatar color="rgb(var(--vs-success))"
                            :icon="tr.beneficiary_type === 'account' ? 'supervisor_account' : 'layers'" />
                          {{ tr.beneficiary }}
                        </vs-chip>
                      </a>
                      <a v-if="tr.beneficiary && tr.beneficiary_type === 'cashier'" :href="$router.resolve({
                        name: 'cashier-details',
                        params: { cashierId: tr.beneficiary_id }
                      }).href">
                        <vs-chip transparent color="danger">
                          <vs-avatar color="rgb(var(--vs-danger))"
                            :icon="tr.beneficiary_type === 'account' ? 'supervisor_account' : 'layers'" />
                          {{ tr.beneficiary }}
                        </vs-chip>
                      </a>
                      <vs-chip transparent color="warning" v-if="!tr.beneficiary">
                        -
                      </vs-chip>
                    </vs-td>
                    <vs-td>
                      <a v-if="tr.affected && tr.affected_type === 'account'" :href="$router.resolve({
                        name: 'AccountTransactions',
                        params: { accountId: tr.affected_id }
                      }).href">
                        <vs-chip transparent color="success">
                          <vs-avatar color="rgb(var(--vs-success))"
                            :icon="tr.affected_type === 'account' ? 'supervisor_account' : 'layers'" />
                          {{ tr.affected }}
                        </vs-chip>
                      </a>
                      <a v-if="tr.affected && tr.affected_type === 'cashier'" :href="$router.resolve({
                        name: 'cashier-details',
                        params: { cashierId: tr.affected_id }
                      }).href">
                        <vs-chip transparent color="success">
                          <vs-avatar color="rgb(var(--vs-success))"
                            :icon="tr.affected_type === 'account' ? 'supervisor_account' : 'layers'" />
                          {{ tr.affected }}
                        </vs-chip>
                      </a>
                      <vs-chip transparent color="warning" v-if="!tr.affected">
                        -
                      </vs-chip>
                    </vs-td>
                    <vs-td>
                      <vx-tooltip v-if="tr.description" :text="tr.description">
                        {{ tr.description.length > 20 ? tr.description.substr(0, 20) + '...' : tr.description }}
                      </vx-tooltip>
                    </vs-td>
                    <vs-td v-if="tr.user">
                      {{ tr.user.name }}
                    </vs-td>
                    <vs-td>
                      <font-awesome-icon @click="editDescription(tr)" class="cursor" color="rgb(var(--vs-success))"
                        :icon="['fas', 'edit']" />
                    </vs-td>
                  </vs-tr>
                </template>
              </template>
            </vs-pagination-table>
          </div>

        </vs-card>
        <vs-prompt :title="$t('Açıklamayı Düzenle')" :accept-text="$t('Update')" @accept="editDescription(editData.id)"
          :cancel-text="$t('Cancel')" class="export-options" :active.sync="editPopup">
          <vs-textarea v-model="editData.description" class="my-4" />

        </vs-prompt>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
/* eslint-disable */
import NewTransactionPopup from "@/views/pages/cashier/NewTransactionPopup";
import vSelect from 'vue-select'
import MySelect from '../../../components/MySelect'
import axios from '../../../axios'

export default {
  components: {
    NewTransactionPopup,
    vSelect,
    MySelect
  },
  data() {
    return {
      newTransactionActive: false,
      //
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['No', 'İssue Date', 'Category', 'Incomming', 'Outcomming', 'Balance', 'Beneficiary', 'Affected',
        'Description'
      ],
      headerVal: ['no', 'issue_date', 'category', 'income', 'outcome', 'balance', 'beneficiary', 'affected', 'description'],
      activePrompt: false,
      filter: {
        description: null,
        category: null,
        sub_category: null,
        issue_date: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toJSON().slice(0, 10).replace(/-/g,
          '-'),
        issue_date_end: new Date().toJSON().slice(0, 10).replace(/-/g, '-')
      },
      queryTimer: null,
      subcategories: [],
      sub_id: null,
      selectedCat: null,
      uniqueCategories: [],
      editPopup: false,
      editData: {},
      allDatas: []
    }
  },
  computed: {
    totalOutcome() {
      let total = this.allDatas.map((x) => {
        let val = x.outcome.toString().replace(',', '')
        return parseFloat(val)
      })
      if (total.length > 0) {
        return total.reduce((a, b) => a + b)
      }
      return 0
    },
    totalIncome() {
      let total = this.allDatas.map((x) => {
        let val = x.income.toString().replace(',', '')
        return parseFloat(val)
      })
      if (total.length > 0) {
        return total.reduce((a, b) => a + b)
      }
      return 0
    },
    queryStrings() {
      let start = null
      let queryStrings = ''
      if (this.filter.description) {
        start = !start ? '?' : '&'
        queryStrings += start + 'description=' + this.filter.description
      }
      if (this.filter.category) {
        start = !start ? '?' : '&'
        queryStrings += start + 'category=' + this.filter.category
      }
      if (this.filter.sub_category) {
        start = !start ? '?' : '&'
        queryStrings += start + 'sub_category=' + this.filter.sub_category
      }
      if (this.filter.issue_date) {
        start = !start ? '?' : '&'
        queryStrings += start + 'issue_date=' + this.filter.issue_date
      }
      if (this.filter.issue_date_end) {
        start = !start ? '?' : '&'
        queryStrings += start + 'issue_date_end=' + this.filter.issue_date_end
      }
      clearTimeout(this.queryTimer)
      this.queryTimer = setTimeout(() => {
        // this.$router.replace({ queryStrings })
        this.$router.push(queryStrings)
        this.getData()
      }, 500)
      return queryStrings
    },
    categories() {
      let val = this.$store.getters["transaction/transactionCategories"]
      console.log(val,"fsdvcsdv")
     
      return val
    },

    transactions() {
      let val = this.$store.getters["cashier/cashierTransactions"]
      return val.data || val
    },
    cashier() {
      return this.$store.getters["cashier/cashier"]
    }
  },

  watch: {
    'categories'(val) {
      this.checkSubCategory()
    }
  },
  methods: {
    editDescription(data) {
      if (typeof data === 'object') {
        this.editPopup = true
        this.editData = {
          ...data
        }
      } else {
        axios.post('transaction/edit/' + data, {
          description: this.editData.description
        }).then(response => {
          this.getData()
        })
      }
    },
    checkSubCategory() {
      if (this.filter.category && this.categories.length > 0) {
        this.subcategories = this.categories.find(x => x.name === this.filter.category).parents
        this.sub_id = null
      }
    },
    cleaner() {
      Object.keys(this.filter).forEach(index => {
        this.$set(this.filter, index, null)
      })
    },

    // noFilterData() {
    //   axios.get('/cashier/transactions/' + this.$route.params.cashierId).then(response => {
    //     let data = response.data.data.map(x => x.top_category)
    //     this.uniqueCategories = [...new Set(data)];
    //   })
    // },

    allData() {
      axios.get('/cashier/transactions/' + this.$route.params.cashierId +
        '?nopaginate=true&' + this.queryStrings.slice(1)).then(response => {
          let data = response.data.data.map(x => x.top_category)
          this.uniqueCategories = [...new Set(data)];

          this.allDatas = response.data.data
        });
    },

    exportToExcel() {
      import('../../../components/excel/ExportExcel').then(excel => {
        const list = this.allDatas
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat || 'xlsx'
        })
        this.clearFields()
      })

    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    clearFields() {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    getData() {
      this.$store.dispatch("transaction/getTransactionCategories")
      var cashierId = this.$route.params.cashierId
      this.$store.dispatch("cashier/getCashierTransactions", {
        'id': cashierId,
        'queryStrings': this.queryStrings
      })
      this.$store.dispatch("cashier/getCashier", cashierId)
      this.allData()
    },
    togglePopup(val = false) {
      this.newTransactionActive = val
    }
  },
  mounted() {
    this.checkSubCategory()
    this.allData()
  },
  created() {
    Object.keys(this.$route.query).forEach(index => {
      // this.filter[index] = this.$route.query[index]
      this.$set(this.filter, index, this.$route.query[index])
    })
    this.getData()
    // this.noFilterData()
    // this.getData()
  }
}

</script>
<style type="text/css">
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
